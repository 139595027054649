import React from "react";
import ReactMarkdown from "react-markdown";

export function render(data) {
  const summary = data.find(item => item.model && item.model.apiKey === "main_summary");
  const photos = data.filter(item => item.model && item.model.apiKey === "team_photo");
  return (
    <React.Fragment>
      <div className="container">
        <h2 className="h2">{summary.title}</h2>
        <div className="sm-spacing" />
        <p className="p center-align">
          <ReactMarkdown source={summary.description} escapeHtml={false} />
        </p>
      </div>
      <div className="md-spacing" />
      <div className="team-photo">
        {photos.map((item, idx) => (
          <img
            key={item.id}
            src={item.photo.url}
            alt={item.photo.alt}
            className={`nugit-photo ${idx === 2 || idx === 3 ? "half" : ""} `}
          />
        ))}
      </div>
    </React.Fragment>
  );
}

import React from "react";
import { graphql } from 'gatsby'
import * as mainHeader from "../components/about/mainHeader";
import * as team from "../components/about/team";
import * as nugitExperience from "../components/about/nugitExperience";
import PressDetail from "../components/press/press";
import { resetWebflow } from "../utils/common";
import { HelmetDatoCms } from "gatsby-source-datocms";
import Schema from "../components/common/schema";
import Notification from "../components/header/notification";
import Layout from "../layouts";

export default ({
  data: {
    datoCmsAbout: {
      seoMetaTags,
      mainHeader: mainHeaderData,
      team: teamData,
      nugitExperience: nugitExperienceData,
      featureOn,
      pressLogo,
      featureNews: featureNewsData,
      backedBy,
      investorLogo,
      schema,
      notificationPage
    },
    allDatoCmsNotification: { edges: notifications }
  }
}) => {
  resetWebflow("5aabd62c51e22e5d9a346d11");

  const mainHeaderTmpl = mainHeader.render(mainHeaderData);
  const teamTmpl = team.render(teamData);
  const nugitExperienceTmpl = nugitExperience.render(nugitExperienceData);
  const featureNewsTmpl = featureNewsData.map(block => {
    return <PressDetail data={block} />;
  });

  return (
    <Layout>
      <HelmetDatoCms seo={seoMetaTags} />
      <Notification
        notifications={notifications}
        linkedPage={notificationPage}
      />
      <Schema data={schema} />
      {mainHeaderTmpl}
      {teamTmpl}

      <div className="white-section centered">
        {nugitExperienceTmpl}
        <div className="hg-spacing" />
        <div className="container grid">
          <h2 className="h2">{featureOn}</h2>
          <div className="md-spacing" />
          {pressLogo.map(logo => (
            <div className="press-logo" key={logo.id}>
              <img src={logo.url} alt={logo.alt} />
            </div>
          ))}
        </div>
        <div className="md-spacing" />
        <div className="container grid">{featureNewsTmpl}</div>
        <div className="hg-spacing" />
        <div className="container grid">
          <h2 className="h2">{backedBy}</h2>
          <div className="md-spacing" />
          <div className="flex-container center">
            {investorLogo.map((logo, idx) => (
              <div className="investor-logo" key={logo.id}>
                <img src={logo.url} alt={logo.alt} width={idx % 3 === 1 ? "280" : ""} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query AboutQuery {
    datoCmsAbout {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      mainHeader {
        ... on DatoCmsSummary {
          title
          subTitle
          model {
            apiKey
          }
        }
        ... on DatoCmsDetailBox {
          id
          boxTitle
          boxSubtitle
          photo {
            alt
            url
          }
          popupTitle
          popupSubtitle
          popupDescription
          linkedinUrl
          model {
            apiKey
          }
        }
      }
      team {
        ... on DatoCmsSummary {
          title
          model {
            apiKey
          }
        }
        ... on DatoCmsTeamMember {
          id
          name
          role
          photo {
            alt
            url
          }
          model {
            apiKey
          }
        }
      }
      nugitExperience {
        ... on DatoCmsMainSummary {
          title
          description
          model {
            apiKey
          }
        }
        ... on DatoCmsTeamPhoto {
          id
          photo {
            alt
            url
          }
          model {
            apiKey
          }
        }
      }
      featureOn
      pressLogo {        
        alt
        url
      }
      featureNews {
        ... on DatoCmsPressDetail {
          id
          title
          image {
            alt
            url
          }
          link
          hostName
          publishedDate(formatString: "MMM DD, YYYY")
        }
      }
      backedBy
      investorLogo {        
        alt
        url
      }
      schema {
        ... on DatoCmsSchemaDetail {
          id
          jsonSchema
        }
      }
      notificationPage {
        id
        page
      }
    }
    allDatoCmsNotification {
      edges {
        node {
          id
          content
          show
          weeks
          notificationPosition
          linkToPage {
            id
            page
          }
          backgroundColor {
            hex
          }
        }
      }
    }
  }
`;

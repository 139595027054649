import React from "react";
import ReactMarkdown from "react-markdown";

const webFlowIds = {
  links: [
    "5d251648-50aa-c481-984b-f3a3c83adb58",
    "68fdefdb-d0b0-47e5-8196-613034e0b4fc"
  ],
  containers: [
    "011c364b-a091-d0e4-7722-533f63ab249c",
    "1d44f0f8-3e08-eb51-eded-11e7c43bd787"
  ],
  closes: [
    "f66f4859-c404-5de9-9263-2bcb031bfe3c",
    "c9306774-22c8-9979-4aee-b8cfedcdce36"
  ],
  contents: [
    "81b43c9e-c6e3-681f-2b56-fe7117d1c4ee",
    "3bc857aa-409a-857e-f6d6-593967f67079"
  ],
  className: ["tint-ceo", "tint-nugit"]
};

export function render(data) {
  const summary = data.find(item => item.model && item.model.apiKey === "summary");
  const details = getDetails(data);
  let videos = renderVideoInsideModal(details);
  return (
    <React.Fragment>
      {videos}
      <header className="main-header about">
        <div className="container">
          <h1 className="h-nofixedwidth">{summary.title}</h1>
          <div className="sm-spacing" />
          <div className="p center-align">{summary.subTitle}</div>
          <div className="md-spacing" />
        </div>
        <div className="container grid">
          {details.map((item, idx) => (
            <a
              key={item.id}
              data-w-id={webFlowIds.links[setIndex(idx)]}
              className="shadow-box about w-inline-block"
            >
              <img src={item.photo.url} alt="nugit" className="avatar about" alt={item.photo.alt}/>
              <div className="avatar-box-content">
                <h5 className="h5">{item.boxTitle}</h5>
                <p className="small-copy">{item.boxSubtitle}</p>
              </div>
              <div className="about-learn">
                <div className="link-text w-clearfix">
                  <div className="learn-more">Learn More</div>
                  <div className="arrow-right" />
                </div>
              </div>
            </a>
          ))}
        </div>
      </header>
    </React.Fragment>
  );
}

export function renderVideoInsideModal(details) {
  return (
    <React.Fragment>
      {details.map((item, idx) => (
        <div
          key={item.id}
          data-w-id={webFlowIds.containers[setIndex(idx)]}
          style={{ display: "none" }}
          className={webFlowIds.className[setIndex(idx)]}
        >
          <div
            data-w-id={webFlowIds.closes[setIndex(idx)]}
            style={{
              WebkitTransform:
                "translateX(0) translateY(-100PX) translateZ(0) scaleX(1) scaleY(1) scaleZ(1) rotateX(0) rotateY(0) rotateZ(0) skewX(0) skewY(0)",
              msTransform:
                "translateX(0) translateY(-100PX) translateZ(0) scaleX(1) scaleY(1) scaleZ(1) rotateX(0) rotateY(0) rotateZ(0) skewX(0) skewY(0)",
              transform:
                "translateX(0) translateY(-100PX) translateZ(0) scaleX(1) scaleY(1) scaleZ(1) rotateX(0) rotateY(0) rotateZ(0) skewX(0) skewY(0)"
            }}
            className="close-popup"
          />
          <div
            data-w-id={webFlowIds.contents[setIndex(idx)]}
            style={{
              WebkitTransform:
                "translateX(0) translateY(-150%) translateZ(0) scaleX(1) scaleY(1) scaleZ(1) rotateX(0) rotateY(0) rotateZ(0) skewX(0) skewY(0)",
              msTransform:
                "translateX(0) translateY(-150%) translateZ(0) scaleX(1) scaleY(1) scaleZ(1) rotateX(0) rotateY(0) rotateZ(0) skewX(0) skewY(0)",
              transform:
                "translateX(0) translateY(-150%) translateZ(0) scaleX(1) scaleY(1) scaleZ(1) rotateX(0) rotateY(0) rotateZ(0) skewX(0) skewY(0)"
            }}
            className="popup-bg"
          >
            <img src={item.photo.url} className="avatar about" />
            <div className="popup-content">
              <div className="popup-title-box">
                <h5 className="h5">{item.popupTitle}</h5>
                <a
                  href={item.linkedinUrl}
                  target="_blank"
                  className="lk-icon revert w-inline-block"
                />
              </div>
              <div className="small-copy">{item.popupSubtitle}</div>
              <div className="sm-spacing _30" />
              <p className="p">
                <ReactMarkdown
                  source={item.popupDescription}
                  escapeHtml={false}
                />
              </p>
            </div>
          </div>
        </div>
      ))}
    </React.Fragment>
  );
}
function setIndex(idx) {
  return idx % 2;
}
function getDetails(data) {
  return data.filter(item => item.model && item.model.apiKey === "detail_box");
}

import React from "react";

export function render(data) {
  const summary = data.find(item => item.model && item.model.apiKey === "summary");
  const members = data.filter(item => item.model && item.model.apiKey === "team_member");
  return (
    <div className="white-section team">
      <div className="container-fluid">
        <div className="container">
          <h2 className="h2">{summary.title}</h2>
          <div className="md-spacing" />
        </div>
        <div className="flex-container team-end">
          {members.map((user, idx) => (
            <div
              key={user.id}                            
              className="manager-team"
            >
              <img src={user.photo.url} width="248" alt={user.photo.alt} />
              <div className="tooltip-team">
                <h6 className="h6">{user.name}</h6>
                <div className="small-copy">{user.role}</div>
              </div>
              <div className="team-name-tablet w-hidden-main">
                <h6 className="h6">{user.name}</h6>
                <div className="small-copy">{user.role}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
